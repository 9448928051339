import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";


function Content404(props) {
    let navigate = useNavigate();
    const {t} = useTranslation();

    function goHome() {
        navigate("./");
    }

    return (
        <Card className="bg-dark text-white">
            <Card.Header>404</Card.Header>
            <Card.Body>
                <Card.Title>{t("404_page.could_not_find_page")}</Card.Title>
                <Card.Text>
                  {window.location.href}
                </Card.Text>
                <Button onClick={()=> goHome()} variant="primary">{t("404_page.to_start_page")}</Button>
            </Card.Body>
        </Card>
    );
}

export default Content404;