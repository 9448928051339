import {Container, Image} from "react-bootstrap";
import StoreButton from "../store_button";
import {AppStoreUrl, PlayStoreUrl} from "../../util/constants";
import GooglePlay from "../../assets/images/google_play.png";
import AppStore from "../../assets/images/app_store.png";
import React from "react";



AppContent.propTypes = {

};

function AppContent(props) {
    return (
        <Container className="center-children">
            <div className={'d-flex flex'}>
                 <div className={'m-3'}>
                <StoreButton url={PlayStoreUrl} image={GooglePlay}/>

                 </div>
                <div  className={'m-3'}>

                <StoreButton url={AppStoreUrl} image={AppStore}/>
                </div>
            </div>
        </Container>
    );
}

export default AppContent;