import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function usePageTracking() {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);



    useEffect(() => {
        ReactGA.initialize("UA-207936324-1");
        setInitialized(true);
    }, []);



    useEffect(() => {
        if (initialized) {
            const pageUrl = location.pathname + location.search;
            ReactGA.set({
                page : pageUrl
            });
            ReactGA.pageview(pageUrl);

        }
    }, [initialized, location]);
}

export default usePageTracking;