import React, {useContext, useState} from 'react';
import {Alert, Button, Container, Modal} from "react-bootstrap";
import {AuthContext} from "../../context/AuthContext";

import {useTranslation} from "react-i18next";
import LoginList from "../../components/LoginList";
import LoginForm from "../../components/LoginForm";
import {auth} from "../../services/FirebaseSetup";
import {deleteUser} from "../../services/repository/user";

AccountDeletion.propTypes = {};


function AccountDeletion() {
    const {user} = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = useState("");
    const {t} = useTranslation();
    const trans = 'account-deletion.';

    return (
        <Container className={"text-dark bg-white"}>
            <h1>{t(trans + 'title')}</h1>
            <p>
                {t(trans + 'subtitle')}
            </p>
            {user != null && <Alert variant={"info"} className={"mt-3"}>{t(trans + 'logged_in_as')}{user.email}</Alert>}
            {!user ? <div className="d-flex flex-column align-items-center justify-content-center">
                <LoginForm/>
                <div className={"mt-3"}>
                    <LoginList/>
                </div>
            </div> : <div className={"d-flex justify-content-center align-items-center"}>
                <Button className="me-3"
                        onClick={async () => await auth.signOut()}>{t(trans + 'button_logout')}</Button>
                <Button className={"ms-3"} variant={"danger"} onClick={handleShow}>{t(trans + 'button_delete')}</Button>
            </div>}
            {message.length > 0 && <Alert variant={"warning"} className={"mt-3"}>{message}</Alert>}
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t(trans + 'modal_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t(trans + 'modal_body')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(trans + 'modal_button_cancel')}
                    </Button>
                    <Button variant="danger" onClick={async () => {
                        try {
                            await deleteUser();
                            setMessage(t(trans + 'on-success'));
                            await auth.signOut();
                            setShow(false);
                        } catch (e: any) {
                            setMessage(t(trans + 'on-failure'));
                        }
                    }}>{t(trans + 'modal_button_delete')}</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}


export default AccountDeletion;