import {API_BASE_URL} from "../../util/constants";
import {auth} from "../FirebaseSetup";



export async function getData(url = '', signal: AbortSignal | null = null) {
    const response = await fetch(API_BASE_URL + url, {
        signal, method: 'GET',
        headers: {
            'Authorization': await jwtToken()
        }
    });
    if (response.ok) {
        return response;
    } else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}


export async function putData(url = '', data = {}) {
    const response = await fetch(API_BASE_URL + url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await jwtToken()
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response;
    } else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}


async function jwtToken() {
    return 'bearer ' + await auth.currentUser?.getIdToken();
}