import {SIZE_LG, SIZE_XL, SIZE_XXL} from "../hooks/use_breakpoints";
import sv from "date-fns/locale/sv";
import enGB from "date-fns/locale/en-GB";
// export const API_BASE_URL = 'http://localhost:5000';
export const API_BASE_URL = 'https://impazto-back.herokuapp.com';


export const GoogleApiKey = 'AIzaSyBh6NByac_rGBs0Ka3RsE1ui9oV4fKT_4U';

export const PlayStoreUrl = 'https://play.google.com/store/apps/details?id=se.isalin.impazto';
export const AppStoreUrl = 'https://apps.apple.com/app/impazto/id1625896028';

export const GooglePosDefaults = {
    sv: {lat: 60, lng: 18}
}


export const routeName = {
    start: '/',
    artwork: 'artwork',
    admin: 'admin',
    report: 'report',
    tag: 'tag',
    terms: 'terms',
    privacy: 'privacy-policy',
    cookiePolicy: 'cookies-policy',
    downloadApp: 'download-app',
    accountDeletion: 'account-deletion'
}


export const Duration = {
    weekToMillis: 604800000
}

export const AuthState = {
    notAuthenticated: 'notAuthenticated',
    authenticated: 'authenticated',
    unknown: 'unknown'
}

export const I18nLng = {
    se: 'se',
    en: 'en'
}


export const UploadProgressDefaults = {
    fileCount: 1,
    fileIndex: 1,
    bytesTransferred: 0,
    totalBytes: 0,
    isUploading: false
};

export const FileSize = {
    oneMegabyte: 1048576
}

export const locales = {
    sv: sv,
    enGB: enGB
}

export const Response = {
    NotFound: "Not Found"
}

export const FetchType = {
    latest: "latest",
    popular: "popular",
    news: "news"
}

export const Currency = {
    eur: "EUR",
    dkk: "DKK",
    sek: "SEK"
}


export const ArtType = {
    any: "-1",
    acrylic: "0",
    oil: "1",
    photo: "2",
    graffiti: "3"
}

export const ArtStatus = {
    any: "-1",
    forSale: "0",
    reserved: "1",
    sold: "2",
    notForSale: "3"
}

export const ImageSize = {
    sm: '120x120',
    md: '240x240',
    lg: '480x480',
    xl: '960x960',
}


export const DesktopBreakpoints = [SIZE_LG, SIZE_XL, SIZE_XXL];