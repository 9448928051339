import { Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

StartContent.propTypes = {};

function StartContent() {
    const {t} = useTranslation();

    return (
        <Container>
            <Row><Col className="center-children mt-5"><h1>
                {t("start_page.start_section.title")}
            </h1></Col></Row>
            <Row>
                <Col>
                    <div className="d-flex flex-column">
                        <b>  {t("start_page.start_section.part1.title")}  </b>
                        <p>{t("start_page.start_section.part1.body")}</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <b> {t("start_page.start_section.part2.title")}</b>
                </Col>
                <Col>
                    <ul>
                        <li>{t("start_page.start_section.part2.list1.row1")}</li>
                        <li>{t("start_page.start_section.part2.list1.row2")}</li>
                        <li>{t("start_page.start_section.part2.list1.row3")}</li>
                        <li>{t("start_page.start_section.part2.list1.row4")}</li>
                        <li>{t("start_page.start_section.part2.list1.row5")}</li>
                        <li>{t("start_page.start_section.part2.list1.row6")}</li>
                    </ul>
                </Col>
                <Col>
                    <div className="d-flex flex-column" style={{color: "white"}}>
                        <ul>
                            <li>{t("start_page.start_section.part2.list2.row1")}</li>
                            <li>{t("start_page.start_section.part2.list2.row2")}</li>
                            <li>{t("start_page.start_section.part2.list2.row3")}</li>
                            <li>{t("start_page.start_section.part2.list2.row4")}</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default StartContent;