import {memo, useEffect, useState} from "react";
import {Marker, GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import PropTypes from "prop-types";
import {GoogleApiKey} from "../util/constants";

MyMap.propTypes = {
    position: PropTypes.object,
    callback: PropTypes.func,
    width : PropTypes.string,
    height : PropTypes.string,
    clickable: PropTypes.bool,
};




function MyMap(props) {

    const {position, callback, width, height, clickable} = props;



    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GoogleApiKey,
    })


    const mapOptions = {
        disableDefaultUI: true,
    };

    return isLoaded ? (
        <GoogleMap
            options={mapOptions}
            clickableIcons={false}
            mapContainerStyle={{width, height}}
            center={position}
            zoom={5}
        >

             <Marker position={position}/>
            <></>
        </GoogleMap>
    ) : <></>
}

export default memo(MyMap);