import React from 'react';
import { NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

DropDownLegalStuff.propTypes = {

};

function DropDownLegalStuff() {

    return (
        <NavDropdown title="Legal" id="nav-dropdown">
            <NavDropdown.Item as={Link} to={"/cookies-policy"}>Cookies</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/terms"}>General terms</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/privacy-policy"}>Privacy Policy</NavDropdown.Item>
        </NavDropdown>
    );
}

export default DropDownLegalStuff;