import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Carousel, Image} from "react-bootstrap";
import {urlToResizedImageUrl} from "../util/helpers";
import {ImageSize} from "../util/constants";

MyCarousel.propTypes = {
    images : PropTypes.array
};

function MyCarousel(props) {
    const {images} = props;
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="d-flex flex-column " >
            <Carousel fade={true} style={{width: "100%",  backgroundColor: "black"}} interval={null} indicators={false} activeIndex={index} controls={false}>
                {images.map((e, index) => {
                    return  <Carousel.Item  key={index}>
                        <div style={{ height : "500px"}} className="d-flex center-children" >
                            <img style={{objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }}
                                 className="d-block"
                                 src={e}
                                 alt={"image " + index + 1  + " of " + images.length}
                            />
                        </div>
                    </Carousel.Item>
                })}
            </Carousel>
            <div style={{overflowX: "auto"}} className="px-5 d-flex justify-content-lg-center">
                {images.map((e, index)=>{
                 return <Image key={index} height={120}  thumbnail={true} onClick={()=> handleSelect(index)} src={urlToResizedImageUrl(e, ImageSize.sm)}/>
                })}
            </div>
        </div>

    );
}

export default MyCarousel;