import React from 'react';
import PropTypes from 'prop-types';
import {Image} from "react-bootstrap";
import {urlToResizedImageUrl} from "../util/helpers";
import {ImageSize} from "../util/constants";

AvatarCircle.propTypes = {
    size : PropTypes.string,
    user : PropTypes.object
};

function AvatarCircle(props) {
    const  {size, user} = props;
    const {profileImgUrl, firstName, surname} = user || {};

    return (
        <div className="d-flex flex-column ">
            <Image width={size} height={size} style={{objectFit: "cover"}}
                   roundedCircle={true}
                   src={urlToResizedImageUrl(profileImgUrl, ImageSize.md)}/>
            <h6 className="text-center">{[firstName, surname].join(' ')}</h6>
        </div>
    );
}

export default AvatarCircle;