import {getData} from "../client";

export async function getById(id) {
    try{
        return await getData(`/artwork/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}





