import React from 'react';
import PropTypes from 'prop-types';
import {Image} from "react-bootstrap";

StoreButton.propTypes = {
    image : PropTypes.string,
    url: PropTypes.string
};

function StoreButton(props) {
    const {image, url} = props;

    return (
            <Image onClick={() => window.open(url)} width={'100%'}  style={{cursor:'pointer'}} src={image}/>

    );
}

export default StoreButton;