import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import LoginForm from "../../components/login-form";
import CenterSpinner from "../../components/common/center_spinner";
import ReportView from "../../components/report/report-view";
import {getById, getByNumber} from "../../services/repository/report";
import {AuthContext} from "../../context/AuthContext";


ReportPage.propTypes = {};


function ReportPage() {
    const {user, isAdmin, initialized} = useContext(AuthContext);
    let {id} = useParams();
    const [reportNumber, setReportNumber] = useState<string>('');
    const [reportId, setReportId] = useState<string>(id ?? '');
    const [report, setReport] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldSearch, setShouldSearch] = useState<boolean>(!!id);
    const [error, setError] = useState<string>('');


    useEffect(() => {

     if(shouldSearch && initialized && isAdmin){
         setShouldSearch(false);
         searchById(reportId);
     }

    }, [initialized, isAdmin]);


    if (!user) {
        return <LoginForm/>
    }


    if (isLoading) {
        return <CenterSpinner/>
    }




    return <div className=" mt-5 pb-5 d-flex flex-column">
        <Row className="mb-5">
            <Col className="d-flex">
                <Form.Control value={reportId} onChange={(e) => setReportId(e.target.value)} placeholder="Report id"/>
                <Button onClick={() => searchById(reportId)}>Search</Button>
            </Col>
            <Col className="d-flex">
                <Form.Control value={reportNumber} onChange={(e) => setReportNumber(e.target.value)}
                              placeholder="Report number"/>
                <Button onClick={() => searchByNumber(reportNumber)}>Search</Button>
            </Col>
        </Row>
        {error && <Alert variant={"warning"}>{error.toString()}</Alert>}
        {report &&
        <ReportView report={report} setReport={setReport}/>
        }
    </div>

    function searchById(id : string) {
        if (isLoading) return;
        setIsLoading(true);
        setError('');
        getById(id)
            .then(value => setReport(value))
            .catch(error => setError(error.toString()))
            .finally(() => setIsLoading(false));
    }

    function searchByNumber(number : string) {
        if (isLoading) return;
        setIsLoading(true);
        setError('');
        getByNumber(number)
            .then(value => setReport(value))
            .catch(error => setError(error.toString()))
            .finally(() => setIsLoading(false));
    }


}

export default ReportPage;