import {deleteData, putData} from "../client";

export async function disable(id, disable) {
    try{
        return await putData(`${baseUrl}/disable/${id}/${disable}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function deleteUser(){
    try {
        await deleteData(`${baseUrl}/delete`);
    }catch (e) {
        console.log(e);
        throw e;
    }
}


const baseUrl = '/user'

