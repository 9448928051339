import React from 'react';
import { Link } from 'react-router-dom';

import {useTranslation} from "react-i18next";
import {routeName} from "../util/constants";

interface Props {
    text: string;
}

const TranslatedTextWithLink: React.FC<Props> = ({ text }) => {
    const { t } = useTranslation();
    // Regular expression to match the placeholder format
    const linkRegex = /\{(.*?)\}/;
    const match = text.match(linkRegex);

    if (match && match[1] ===  routeName.accountDeletion) {
        const linkPath = '/' +  routeName.accountDeletion; // Define the path for the link
        const textParts = text.split(linkRegex);

        return (
            <p>
                {textParts[0]}
                <Link to={linkPath}>{t('link.here')}</Link>
                {textParts[2]}
            </p>
        );
    } else {
        // Return the text as-is if no matching placeholder is found
        return <p>{text}</p>;
    }
};

export default TranslatedTextWithLink;
