import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

AboutContent.propTypes = {

};

function AboutContent(props) {
    const { t } = useTranslation();

    return (
        <Container className="mt-5">
            <Row><Col className="center-children"><h1>
                {t('start_page.about_section.title')}
            </h1></Col></Row>
            <Row>
                <Col>
                    <div className="d-flex flex-column" >
                        <p>
                            {t('start_page.about_section.body')}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <b>
                        {t('start_page.about_section.part1.title')}
                    </b>
                </Col>
                <Col>
                    <p>{t('start_page.about_section.part1.subTitle')}</p>
                    <ul>
                        <li>{t('start_page.about_section.part1.list1.row1')}</li>
                        <li>{t('start_page.about_section.part1.list1.row2')}</li>
                        <li>{t('start_page.about_section.part1.list1.row3')}</li>
                        <li>{t('start_page.about_section.part1.list1.row4')}</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutContent;