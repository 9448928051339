import {Spinner} from "react-bootstrap";

CenterSpinner.propTypes = {

};

function CenterSpinner(props) {
    return (
        <div className="w-100 h-100 center-children"><Spinner variant="primary" animation="border"/>
        </div>
    );
}

export default CenterSpinner;