import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "../../styles/start_page/faq.scss"
import {Link} from "react-router-dom";
import {LinkItEmail} from "react-linkify-it";
import TranslatedTextWithLink from "../TranslatedTextWithLink";

FaqContent.propTypes = {};

function FaqContent(props) {
    const {t} = useTranslation();

    return (
        <Container className="faq-container mt-5">
            <Row>
                <Col>
                    <h2>
                        {t("start_page.faq_section.title")}
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <b>
                        {t("start_page.faq_section.sub_title")}
                    </b>
                </Col>
                <Col xs={12} className="mt-3">
                    <ul>
                        <li>
                            <b>
                                {t("start_page.faq_section.sub_list.0")}
                            </b>
                        </li>
                        <li>
                            <b>
                                {t("start_page.faq_section.sub_list.1")}
                            </b>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part1.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part1.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2>{t("start_page.faq_section.part2.title")}</h2>
                </Col>
                <Col>
                    <LinkItEmail>
                        <p>{t("start_page.faq_section.part2.body")} </p>

                    </LinkItEmail>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part3.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part3.body")}</p>

                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part4.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part4.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part5.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part5.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part6.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part6.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part7.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part7.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part8.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part8.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part9.title")}</h3>
                </Col>
                <Col>
                    <p>{t("start_page.faq_section.part9.body")}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3>{t("start_page.faq_section.part10.title")}</h3>
                </Col>
                <Col>
                    <TranslatedTextWithLink text={t("start_page.faq_section.part10.body")}/>
                </Col>
            </Row>
        </Container>
    );
}

export default FaqContent;