import {Container} from "react-bootstrap";
import SubPage from "../../components/start/sub_page";
import "../../styles/start_page/main.scss"
import MyNavbar from "../../components/start/navbar";
import PropTypes from "prop-types";

SimplePageContainer.propTypes = {
    content: PropTypes.element
}


function SimplePageContainer(props) {

    const {content} = props;

    return (
        <div className="bg">
            <MyNavbar showNavbar={false}/>
            <Container className="start-container">
                <SubPage id="start-section" content={content}/>
            </Container>
        </div>
    );
}

export default SimplePageContainer;