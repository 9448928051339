import React from "react";
import {Image, Nav, Navbar} from "react-bootstrap";
import logo from "../../assets/images/logo.png"
import "../../styles/start_page/navbar.scss"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../common/language_selector";
import DropDownLegalStuff from "./drop_down_legal_stuff";

MyNavbar.defaultProps = {
    showNavbar: true
}

MyNavbar.propTypes = {
    showNavbar: PropTypes.bool
}


function MyNavbar(props) {
    const {showNavbar} = props;
    const {t} = useTranslation();


    return (
        <>
            <Navbar fixed="top" collapseOnSelect style={{backgroundColor: "black"}} expand="lg" variant="dark">
                <Link to="/">
                    <Navbar.Brand>
                        <Image className="logo" src={logo}/>
                    </Navbar.Brand>
                </Link>

                {showNavbar &&
                <> <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link href="#start-section">{t('start_page.navbar.link_start')}</Nav.Link>
                            <Nav.Link href="#app-section">{t('start_page.navbar.link_app')}</Nav.Link>
                            <Nav.Link href="#about-section">{t('start_page.navbar.link_about')}</Nav.Link>
                            <Nav.Link href="#faq-section">{t('start_page.navbar.link_faq')}</Nav.Link>
                            <DropDownLegalStuff/>
                            <div className="d-flex mx-3">
                                <LanguageSelector/>
                            </div>
                        </Nav>
                    </Navbar.Collapse></>
                }

            </Navbar>
        </>
    );
}


export default MyNavbar;
