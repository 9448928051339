import {format, formatISO, fromUnixTime, intervalToDuration, isSameDay} from "date-fns";
import {DesktopBreakpoints, locales} from "./constants";

export const isFile = input => 'File' in window && input instanceof File;

export function changeMousePointer(e) {
    e.target.style.cursor = 'pointer';
}

export function isValidType(values, validTypes) {
    let isValid = true;
    values.forEach(item => {
        if (isFile(item)) {
            if (!validTypes.includes(item.type)) {
                isValid = false;
            }
        }
    })

    return isValid;
}

export function isValidFileSize(values, maxSize) {
    let isValid = true;
    values.forEach(item => {
        if (isFile(item)) {
            if (item.size > maxSize) {
                isValid = false;
            }
        }
    })
    return isValid;
}



export function formatBytes(a, b = 2) {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}

export function isValidImageType(type) {
    const conditions = ['image/png', 'image/jpeg', 'image/jpg'];
    return conditions.includes(type);
}

export function isNumber(value) {
    return typeof value === 'number' && isFinite(value);
}

export function equals(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function removeNullValues(list) {
    return list.filter(item => item != null || item !== undefined)
}

export function emptyString(string) {
    return string == null || string.length < 1;
}

export function urlToResizedImageUrl(url, size) {
    if(!url) return '';
    if(!size) return url;
    const pos = url.lastIndexOf(".");
    return url.substring(0, pos) + `_${size}.` + url.substring(pos + 1);
}



export function parseFormatEpoch(epochMillis) {
    const date = fromUnixTime(epochMillis);
    return formatISO(date, {representation: "date"});
}


export function postDate(createdAt, t) {
    let postDate;
    const locale = locales[t("util.helper.post_date.locale")];
    const date = new Date(createdAt);
    const now = Date.now();
    const duration = intervalToDuration({
        start: date,
        end: now
    })
    if (duration.months > 6) {
        postDate = format(date, t("util.helper.post_date.format_date"), {locale: locale});
    } else if (duration.days > 6) {
        postDate = format(date, t("util.helper.post_date.format_date_time"), {locale: locale});
    } else if (!isSameDay(date, now)) {
        postDate = format(date, t("util.helper.post_date.format_day_time"), {locale: locale});
    } else if (duration.hours > 0) {
        postDate = t("util.helper.post_date.today", {time: format(date, t("util.helper.post_date.format_time"), {locale: locale})});
    }
    if (!emptyString(postDate)) {
        postDate = firstLetterToUppercase(postDate);
    } else {
        if (duration.minutes > 0) {
            postDate = t("util.helper.post_date.minute", {minutes: duration.minutes});
        } else if (duration.seconds > 0) {
            postDate = t("util.helper.post_date.second", {seconds: duration.seconds});
        } else {
            postDate = t("util.helper.post_date.minute");
        }
    }

    return postDate;
}



function firstLetterToUppercase(string) {
    return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export function compareStrings(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}




export function updatePageQueryData(queryClient, newObject, queryKey) {
    queryClient.setQueryData(queryKey, (data) => handlePageUpdate(data, newObject));
}

function handlePageUpdate(queryData, newItem) {
    queryData.pages = queryData.pages.map(page => {
        page.result = replaceObjectInArrayById(page.result, newItem);
        return page;
    })
    return queryData;
}

export function formatToMonthDay(date) {
    return format(new Date(date), "MM/dd");
}

export function formatToMonthDayTime(date) {
    return format(new Date(date), "MM/dd HH:mm");
}

export function formatToTime(date) {
    return format(new Date(date), "HH:mm");
}

export function replaceObjectInArrayById(arr, replaceItem) {
    const index = arr.findIndex(item => item.id === replaceItem.id);
    if (index !== -1) {
        arr[index] = replaceItem;
    }
    return arr;
}

export function createQueryString(params) {
    const esc = encodeURIComponent;
    return Object.keys(params).map(k => `${esc(k)}=${esc(params[k])}`).join('&')
}

export function isDesktop(value) {
    return DesktopBreakpoints.includes(value);
}


export function getReportSubject(index){

    switch (index) {
        case 0:
            return 'spam';
        case 1:
            return 'porn';
        case 2:
            return 'harmful';
        case 3:
            return 'illegal';
        case 4:
            return 'other';
        default:
            return 'error!'
    }
}

