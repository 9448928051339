import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {Alert, Button, Container} from "react-bootstrap";

import LoginForm from "../../components/login-form";
import {routeName} from "../../util/constants";
import {AuthContext} from "../../context/AuthContext";
import MySpinner from "../../components/MySpinner";
import {auth} from "../../services/FirebaseSetup";
import {signOut} from "firebase/auth";


function AdminPage() {
    const {user, isAdmin, initialized} = useContext(AuthContext);
    const [isBusy, setIsBusy] = useState(initialized);
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //
    //     if (!isAdmin && isInSubPath() && initialized) {
    //         goBack();
    //     }
    //
    // }, [initialized, isAdmin, location]);


    if (isBusy) {
        return <Container className="d-flex justify-content-center align-items-center">
            <MySpinner/>
        </Container>
    }


    function isInSubPath() {
        return location.pathname.includes(routeName.report)
            || location.pathname.includes(routeName.tag);
    }


    function goBack() {
        navigate("./");
    }


    async function handleSignOut() {
        setIsBusy(true);
        try {
            await signOut(auth).then(
                () => navigate("")
            );
        } catch (e) {

        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Container style={{minHeight: "100vh"}}
                   className="d-flex flex-column justify-content-center text-light">
            {!user && <div className="d-flex justify-content-center">
                <LoginForm/>
            </div>}
            {user && !isAdmin && <Alert variant={"warning"}>401 Unauthorized</Alert>}
            {user && isAdmin && <Outlet/>}
            {user &&
            <div className="d-flex mt-5 justify-content-around">
                <Button onClick={handleSignOut}>Sign out</Button>
                {isInSubPath() && <Button onClick={() => goBack()}>Back to Menu</Button>}
            </div>}
        </Container>
    );
}

export default AdminPage;