import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import "../../styles/start_page/sub_page.scss"


SubPage.propTypes = {
    id: PropTypes.string,
    content: PropTypes.object
};

function SubPage(props) {
    const {id, content} = props;
    return (
        <Row className="sub-page mt-5" id={id}>
            <Col className="center-children">
                {content}
            </Col>
        </Row>
    );
}

export default SubPage;