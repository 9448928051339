import React, {useEffect, useState} from 'react';
import CookieConsentBar from "./cookies/cookie_consent_bar";
import {Navbar} from "react-bootstrap";
import DownloadAppBar from "./download_app_bar";
import {
    isAndroid,
    isIOS
} from "react-device-detect";



BottomBar.propTypes = {

};

function BottomBar(props) {
   const [showCookiesBar, setShowCookiesBar] = useState(true);
   const [showDownloadAppbar, setShowDownloadAppBar] = useState(isAndroid || isIOS);
   const cookieKey = 'cookieConsentTimeStamp';
   const monthInMillis = 2629800000;

   useEffect(()=>{
     const  timestamp =  localStorage.getItem(cookieKey);
     if(timestamp && parseInt(timestamp) > Date.now() ){
        setShowCookiesBar(false);
     }

   }, []);

   function handleCookiesClick(){
       let timestamp = Date.now();
       timestamp += monthInMillis;
       localStorage.setItem(cookieKey, timestamp.toString());
       setShowCookiesBar(false);
   }

    return (
        <div className={showCookiesBar || showDownloadAppbar ? "d-block" : "d-none"}>
            <Navbar className="justify-content-center"  style={{color: "white"}} bg={"dark"} variant={"dark"} fixed="bottom">
              <div className="d-flex flex-column">
                  <CookieConsentBar show={showCookiesBar} onClick={handleCookiesClick}/>
                  <DownloadAppBar show={showDownloadAppbar} onClick={()=> setShowDownloadAppBar(false)}/>
              </div>
            </Navbar>
        </div>
    );
}

export default BottomBar;