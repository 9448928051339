import { initializeApp } from "@firebase/app";
import {getAuth} from  '@firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBh6NByac_rGBs0Ka3RsE1ui9oV4fKT_4U",
    authDomain: "impazto-bdfef.firebaseapp.com",
    projectId: "impazto-bdfef",
    storageBucket: "impazto-bdfef.appspot.com",
    messagingSenderId: "436679697903",
    appId: "1:436679697903:web:de5d7f5c6a0d3065c2021a",
    measurementId: "G-4L0CYFK752"
};

const  app = initializeApp(firebaseConfig);
export const  auth = getAuth(app);