import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {getById} from "../../services/repository/artwork";
import {Alert, Card, Col, Row} from "react-bootstrap";
import CenterSpinner from "../../components/common/center_spinner";
import { signInAnonymously } from "firebase/auth";
import MyMap from "../../components/my_map";
import MyCarousel from "../../components/my_carousel";
import AvatarCircle from "../../components/avatar-circle";
import {AuthContext} from "../../context/AuthContext";
import {auth} from "../../services/FirebaseSetup";

ContentArtwork.propTypes = {};

function ContentArtwork(props) {
    let {artworkId} = useParams();
    const {user : fbUser, isAdmin, initialized} = useContext(AuthContext);
    const size = '150px';

    const forSale = () =>
        status === 'forSale';

    const {
        data,
        isLoading,
        isIdle,
        isError
    } = useQuery(artworkId, () => getById(artworkId), {enabled: initialized && fbUser !== null});
    const {user, imageUrls, title, description, status, price} = data || {};
    const {latLng} = user || {};

    useEffect(() => {
        if (!fbUser && initialized) {
            signInAnonymously(auth).then(r => {
                console.log('Logged in anonymously');
            }).catch(error => {
                console.log(error.toString());
            });
        }
    }, [fbUser, initialized]);

    if (isLoading || isIdle) {
        return CenterSpinner();
    }
    if (isError) {
        return <Alert variant={"warning"}>{'Something went wrong! ;/'}</Alert>
    }


    return (
        <div><Row>
            <Col>
                <Card bg={'dark'} style={{width: "90vw"}} className="d-flex flex-column">
                    <MyCarousel images={imageUrls}/>
                    <Card.Body>
                        <Row>
                            <Col className="d-flex flex-column justify-content-between" lg={6}>
                                <div>
                                    <Card.Title>{title}</Card.Title>
                                    <Card.Text>
                                        {description}
                                    </Card.Text>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="mr-4" style={{
                                        borderRadius: "50%",
                                        height: "20px",
                                        width: "20px",
                                        backgroundColor: forSale ? "Lime" : "LightGrey"
                                    }}>
                                    </div>
                                    <div>
                                        <h6 style={{textDecoration: !forSale && "line-through"}}>
                                            €{price}
                                        </h6>
                                    </div>
                                </div>
                            </Col>

                            <Col>
                                <div className="d-flex justify-content-around">
                                    <AvatarCircle size={size} user={user}/>
                                    <div className="d-flex flex-column">
                                        <MyMap width={size} height={size} position={{lat: latLng[0], lng: latLng[1]}}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row></div>
    );
}

export default ContentArtwork;