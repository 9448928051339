import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";

CookieModalSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array
};

function CookieModalSection(props) {
    const {title, description} = props;

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    {title}
                </Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default CookieModalSection;