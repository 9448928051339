import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import CookiePolicyModal from "./cookie_consent_modal";

CookieConsentBar.propTypes = {
    onClick: PropTypes.func,
    show: PropTypes.bool
};

function CookieConsentBar(props) {
    const {t} = useTranslation();
    const {onClick, show} = props;
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <div className={show ? "d-flex" : "d-none"}>
                <div className="flex-grow-1 mr-1">
                    <p>
                        {t("component.cookies_consent_bar.body")} <Link to={""} onClick={() => setShowModal(true)}>cookie
                        policy</Link>
                    </p>
                </div>
                <div className={"ml-1"}>
                    <Button onClick={() => onClick()}>
                        {t("component.cookies_consent_bar.button_accept")}
                    </Button>
                </div>
                <div>
                </div>
            </div>
            <CookiePolicyModal
                onHide={() => setShowModal(false)} show={showModal}
            />
        </div>
    );
}

export default CookieConsentBar;