import React from "react";
import firebase from "@firebase/auth";






interface AuthContextInterface {
    user: firebase.User | null;
    initialized: boolean;
    isAdmin : boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({user: null, initialized: false, isAdmin : false});