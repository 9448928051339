import PropTypes from 'prop-types';

MailLink.propTypes = {
    email: PropTypes.string,
    subject : PropTypes.string
};

function MailLink(props) {
    const {email , subject} = props;
    const mailSubject = () => subject ? `?subject=${subject}` : '';



    return (
        <div className="d-inline-block">
            <a href={`mailto: ${email + mailSubject()}`}>
                <div>
                    <i className="bi bi-envelope "/> <span>{email}</span>
                </div>
            </a>
        </div>
    );
}

export default MailLink;