import "./styles/app.scss";
import {Container} from "react-bootstrap";
import Start from "./pages/start"
import React from "react";
import SimplePageContainer from "./pages/start/simple-page-container";
import Content404 from "./pages/start/content-404";
import ContentTerms from "./pages/start/content-terms";
import ContentPrivacy from "./pages/start/content-privacy";
import BottomBar from "./components/bottom_bar";
import DownloadApp from "./components/download_app";
import usePageTracking from "./hooks/use_tracking";
import ContentArtwork from "./pages/start/content-artwork";
import ContentCookies from "./pages/start/content-cookies";
import {Route, Routes} from "react-router";
import {routeName} from "./util/constants";
import AdminPage from "./pages/admin/AdminPage";
import AdminNavigation from "./pages/admin/AdminNavigation";
import ReportPage from "./pages/admin/ReportPage";
import PageTag from "./pages/admin/PageTag";
import AccountDeletion from "./pages/account_deletion/AccountDeletion";

function App() {
    usePageTracking();


    return <Container fluid className="p-0 position-relative">
        <Routes>
            <Route path={routeName.start} element={<Start/>}/>
            <Route path={routeName.accountDeletion} element={<AccountDeletion/>}/>
            <Route path={routeName.artwork}>
              <Route path={":artworkId"} element={<SimplePageContainer content={<ContentArtwork/>}/>}/>
            </Route>
            <Route path={routeName.admin} element={<SimplePageContainer content={<AdminPage/>}/> }>
                <Route path={routeName.report + "/:id"} element={<ReportPage/>}/>
                <Route path={routeName.report} element={<ReportPage/>}/>
                <Route path={routeName.tag} element={<PageTag/>}/>
                <Route path={''} element={<AdminNavigation/>}/>
            </Route>
            <Route path={routeName.terms} element={<SimplePageContainer content={<ContentTerms/>}/>}/>
            <Route path={routeName.privacy} element={<SimplePageContainer content={<ContentPrivacy/>}/>}/>
            <Route path={routeName.cookiePolicy} element={<SimplePageContainer content={<ContentCookies/>}/>}/>
            <Route path={routeName.downloadApp} element={<DownloadApp/>}/>
            <Route element={<SimplePageContainer content={<Content404/>}/>}/>

        </Routes>
        <div className="d-flex flex-column">
            <BottomBar/>
        </div>

    </Container>;
}

export default App;
