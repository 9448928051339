import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {Button, Image} from "react-bootstrap";
import googlePlayImage from "../assets/images/google_play.png";
import appStoreImage from "../assets/images/app_store.png";
import {
    isAndroid,
    isIOS
} from "react-device-detect";
import {useTranslation} from "react-i18next";


DownloadAppBar.propTypes = {
show: PropTypes.bool,
    onClick: PropTypes.func
};

function DownloadAppBar(props) {
  const{show, onClick} = props;
    const image =  isIOS ? appStoreImage : googlePlayImage;
    const {t} = useTranslation();

    return (
        <div className={show ? "d-flex justify-content-center" : "d-none"}>
            <div className="center-children">
                <div className="d-flex">
                    <div className="d-flex flex-column">
                        <div>
                            <Link target="_blank"
                                  rel="noopener noreferrer"
                                  to={"/download-app"}><Image height={50} src={image}/> </Link>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-content-center">
                    <div className=" p-1">
                        <span>
                            {t("component.download_app_bar.continue_with")}
                        </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <Button  variant="outline-primary" onClick={()=> onClick()}>{t("component.download_app_bar.button_browser")}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadAppBar;