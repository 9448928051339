import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {getReportSubject} from "../../util/helpers";
import AvatarCircle from "../avatar-circle";
import MailLink from "../common/mail_link";
import {disable} from "../../services/repository/user";

ReportView.propTypes = {
    report: PropTypes.object,
    setReport: PropTypes.func
};

function ReportView(props) {
    const {setReport, report} = props;
    const {number, subject, reporter, regarding, description} = report;
    const {disabled} = regarding;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)


    return (
        <div>
            <Row>
                <Col>
                    <h6>Report #{number}</h6>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <h6>Reporter: </h6>
                    <Card bg={'dark'}>
                        <Card.Body>
                            <div className="d-flex flex-column">

                                <AvatarCircle size={'100px'} user={reporter}/>
                                <MailLink email={reporter.email}
                                          subject={`#${report.number}, ${getReportSubject(report.subject)} `}/>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <h6>Subject: {getReportSubject(subject)}</h6>
                    <Card bg={'dark'}>
                        <Card.Body>
                            <div className="d-flex flex-column">

                                <h6>Description</h6>
                                <p>{description}</p>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <h6>Regarding User: </h6>
                    <Card bg={'dark'}>
                        <Card.Body>
                            <div className="d-flex flex-column">

                                <div className="d-flex center-children">
                                    <Alert
                                        variant={disabled ? "warning" : "success"}> {disabled ? 'Disabled' : 'Active'} </Alert>

                                </div>
                                <AvatarCircle size={'100px'} user={regarding}/>
                                <MailLink email={regarding.email}
                                          subject={`#${report.number}, ${getReportSubject(report.subject)} `}/>
                                <div className="my-2 d-flex flex-column center-children">
                                    <Button className="d-flex center-children"
                                            onClick={() => handleClick()}> {isLoading ? '' : regarding.disabled ? 'Activate' : 'Disable'}
                                        {
                                            isLoading &&
                                            <Spinner animation={'border'}/>
                                        }
                                    </Button>
                                    {error && <Alert variant={"warning"}>{error} </Alert>}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );

    function handleClick() {
        if (isLoading) return;
        setIsLoading(true);
        setError(null);
        disable(regarding.id, !regarding.disabled)
            .then(value => setReport(oldState => {
                oldState.regarding.disabled = value;
                return {...oldState};
            }))
            .catch(error => setError(error.toString()))
            .finally(() => setIsLoading(false));
    }

}

export default ReportView;