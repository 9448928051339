import React, {useEffect} from 'react';
import {
    isAndroid,
    isIOS
} from "react-device-detect";
import {AppStoreUrl, PlayStoreUrl} from "../util/constants";


DownloadApp.propTypes = {

};

function DownloadApp(props) {

    useEffect(() => {
        if (isAndroid) {
            window.location.href =
                PlayStoreUrl;
        }else if(isIOS) {
            window.location.href =
                AppStoreUrl;
        } else{
            window.location.href =
                "https://impazto.com";
        }
    }, []);



    return (
        <div/>
    );
}

export default DownloadApp;