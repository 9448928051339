import React from 'react';
import {Spinner} from "react-bootstrap";

MySpinner.propTypes = {};

function MySpinner() {
    return (
        <Spinner variant={"primary"} animation="border"/>
    );
}

export default MySpinner;