import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {routeName} from "../../util/constants";
import '../../styles/admin_nav.scss'

AdminNavigation.propTypes = {

};

function AdminNavigation() {

    let navigate = useNavigate();


    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <Card className='admin-nav bg-dark'>
               <Card.Body className="d-flex flex-column ">
                   <Button onClick={() => navigate(routeName.report)}>Report</Button>
                   <Button onClick={() => navigate(routeName.tag)}>Tag</Button>
               </Card.Body>
            </Card>
        </div>
    );
}

export default AdminNavigation;