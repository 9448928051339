import {Container} from "react-bootstrap";
import SubPage from "../components/start/sub_page";
import MyNavbar from "../components/start/navbar";
import Footer from "../components/start/footer";
import "../styles/start_page/main.scss"
import "../styles/start_page/modal.scss"

import StartContent from "../components/start/start_content";
import AppContent from "../components/start/app_content";
import AboutContent from "../components/start/about_content";
import FaqContent from "../components/start/faq_content";


function Start(props) {
    return (
        <div className="bg">
            <MyNavbar/>
            <Container className="start-container pt-5">
                <SubPage id="start-section" content={<StartContent/>}/>
                <SubPage id="app-section" content={<AppContent/>}/>
                <SubPage id="about-section" content={<AboutContent/>}/>
                <SubPage id="faq-section" content={<FaqContent/>}/>
            </Container>
            <Footer/>
        </div>
    );
}

export default Start;