import {Col, Row} from "react-bootstrap";
import "../../styles/start_page/footer.scss"
import GooglePlay from "../../assets/images/google_play.png"
import AppStore from "../../assets/images/app_store.png"
import MailLink from "../common/mail_link";
import {useTranslation} from "react-i18next";
import StoreButton from "../store_button";
import {AppStoreUrl, PlayStoreUrl} from "../../util/constants";
import React from "react";


Footer.propTypes = {
}


function Footer(props) {
    const { t } = useTranslation();


    return (
      <Row className="footer text-center">
          <Col className="footer-child">
           <h3>
               {t("start_page.footer.part_app.title")}
           </h3>
            <div className="d-flex flex-column align-items-center">
                <div  className="store-button">
                    <StoreButton url={PlayStoreUrl} image={GooglePlay}/>

                </div>
                <div  className="store-button">

                    <StoreButton url={AppStoreUrl}  image={AppStore}/>
                </div>

            </div>
          </Col>
          <div className="divider"/>
          <Col className="footer-child">
          </Col>
          <div className="divider"/>
          <Col className="footer-child">
           <h3>{t("start_page.footer.part_contact.title")}</h3>
              <div className="mt-3">
                  <MailLink email={"info@impazto.com"}/>
              </div>
          </Col>
      </Row>
    );
}

export default Footer;