import React, {useEffect, useState} from "react";

import {AuthContext} from "../context/AuthContext";
import {auth} from "../services/FirebaseSetup";
import firebase from "@firebase/auth";



function AuthProvider(props: React.PropsWithChildren<{}>) {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        return auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
            firebaseUser?.getIdTokenResult().then(value => {
                setIsAdmin(value.claims.admin ?? false)
            });

            if (!initialized) {
                setInitialized(true);
            }
            console.log(`onAuthStateChanged user: ${firebaseUser}`);
        });

    }, [initialized]);

    return <AuthContext.Provider value={{user, initialized, isAdmin}} {...props}/>;

}

export default AuthProvider;